import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VFlex } from 'vuetify/lib/components/VGrid';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VLayout } from 'vuetify/lib/components/VGrid';
import { VSelect } from 'vuetify/lib/components/VSelect';
import { VSpacer } from 'vuetify/lib/components/VGrid';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VCard,[_c(VCardText,[_c(VLayout,{attrs:{"row":"","align-content-space-between":""}},[_c(VFlex,[_c(VSelect,{attrs:{"label":"Kategori","items":_vm.categoryFilterItems,"item-value":"categoryId"},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.name)+" ")]}},{key:"item",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.name)+" ")]}}]),model:{value:(_vm.categoryId),callback:function ($$v) {_vm.categoryId=$$v},expression:"categoryId"}})],1),_c(VSpacer),_c(VFlex)],1),_c(VDataTable,{attrs:{"headers":_vm.headers,"items":_vm.filteredSubcategories,"item-key":"subcategoryId","sort-by":"lgVersions[0].name","disable-pagination":"","hide-default-footer":"","dense":""},scopedSlots:_vm._u([{key:"item",fn:function(ref){
var item = ref.item;
return [_c('tr',{on:{"click":function($event){$event.stopPropagation();return _vm.showItemDialog(item)}}},[_c('td',[_vm._v(_vm._s(item.subcategoryId))]),_c('td',[_vm._v(_vm._s(_vm.getUiVersion(item).name))]),_c('td',[_vm._v(_vm._s(_vm.getCategoryName(item.categoryId)))]),_c('td',_vm._l((item.lgVersions),function(lgVersion){return _c('country-flag',{key:lgVersion.lgCode,attrs:{"country":_vm.countryCodeForLgCode(lgVersion.lgCode),"size":"small"}})}),1),_c('td',[_vm._v(_vm._s(_vm.formatDateTime(item.lastUpdated)))]),_c('td',[_c(VIcon,{attrs:{"color":"warning"},on:{"click":function($event){$event.stopPropagation();return _vm.remove(item)}}},[_vm._v(" mdi-delete-forever ")])],1)])]}}])})],1),_c(VCardActions,[_c(VSpacer),_c('DialogEdit',{attrs:{"title":"Opprett underkategori","create-model":_vm.createNewModel,"save":_vm.save,"support-languages":true},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c(VIcon,_vm._g({},on),[_vm._v(" mdi-playlist-plus ")])]}},{key:"edit",fn:function(ref){
var model = ref.model;
var language = ref.language;
return [_c('BasedataSubcategoryEdit',{attrs:{"model":model,"language":language,"create-lg-version":_vm.createLgVersion}})]}}])})],1),_c('DialogEdit',{ref:"itemDialog",attrs:{"title":"Underkategori","edit-title":"Rediger underkategori","save":_vm.save,"support-languages":true},scopedSlots:_vm._u([{key:"view",fn:function(ref){
var model = ref.model;
var language = ref.language;
return [_c('BasedataSubcategoryView',{attrs:{"model":model,"language":language}})]}},{key:"edit",fn:function(ref){
var model = ref.model;
var language = ref.language;
return [_c('BasedataSubcategoryEdit',{attrs:{"model":model,"language":language,"create-lg-version":_vm.createLgVersion}})]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }