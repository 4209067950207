import { VContainer } from 'vuetify/lib/components/VGrid';
import { VFlex } from 'vuetify/lib/components/VGrid';
import { VImg } from 'vuetify/lib/components/VImg';
import { VLayout } from 'vuetify/lib/components/VGrid';
import { VSelect } from 'vuetify/lib/components/VSelect';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VContainer,{attrs:{"grid-list-md":""}},[(_vm.show)?_c(VLayout,{attrs:{"wrap":""}},[_c(VFlex,{attrs:{"xs12":""}},[(!_vm.subcategoryId)?_c(VSelect,{attrs:{"autofocus":"","label":"Kategori*","items":_vm.categoryLookups,"item-value":"id","item-text":"name","rules":[_vm.rules.required]},model:{value:(_vm.model.categoryId),callback:function ($$v) {_vm.$set(_vm.model, "categoryId", $$v)},expression:"model.categoryId"}}):_vm._e(),(_vm.subcategoryId)?_c(VTextField,{attrs:{"label":"Kategori*","readonly":""},model:{value:(_vm.categoryUiName),callback:function ($$v) {_vm.categoryUiName=$$v},expression:"categoryUiName"}}):_vm._e()],1),_c(VFlex,{attrs:{"xs12":""}},[_c(VTextField,{attrs:{"label":"Navn*","maxlength":"50","rules":[_vm.rules.required]},scopedSlots:_vm._u([{key:"append",fn:function(){return [_c('country-flag',{attrs:{"country":_vm.language.flagCountryCode,"size":"small"}})]},proxy:true}],null,false,2910774066),model:{value:(_vm.name),callback:function ($$v) {_vm.name=$$v},expression:"name"}})],1),_c(VFlex,{attrs:{"xs12":""}},[_c(VTextField,{attrs:{"label":"Std. hovedbilde ID for butikker","rules":[_vm.rules.positiveInteger]},model:{value:(_vm.defaultStoreMainImageVaultId),callback:function ($$v) {_vm.defaultStoreMainImageVaultId=$$v},expression:"defaultStoreMainImageVaultId"}})],1),_c(VFlex,{attrs:{"xs12":""}},[(_vm.defaultStoreMainImageUrl)?_c(VImg,{attrs:{"src":_vm.defaultStoreMainImageUrl}}):_vm._e()],1)],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }